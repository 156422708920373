<template>
  <v-card class="mx-1">
    <v-img :src="srcBanner"></v-img>
    <v-card-title>
      Seja bem-vindo(a) à Rede social filosófica "φ Dialeticos"!
    </v-card-title>
    <v-card-text>
      <p>
        Utilize o menu ao seu lado esquerdo para navegar no sistema e o menu
        superior esquerdo para acessar seu perfil ou seu feed de debates.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import bannerImage from "../assets/banner_logo.jpg";

export default {
  data() {
    return {
      srcBanner: bannerImage,
    };
  },
};
</script>
